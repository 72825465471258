import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  ClientIdentifier,
  Environment,
  Metadata,
  SAUL_ACTIONS,
} from '../types';
import { initialState, saulReducer } from '../reducers/saulReducer';
import { useGraphQLClient } from './useGraphQLClient';
import useWidget from './useWidget';
import useAssociateStaffingAssignments from './useAssociateStaffingAssignments';
import useNodeConfiguration from './useNodeConfiguration';
import { UserStaffingAssignment } from '../graphql/generated';
import deepEqual from 'deep-equal';
import {
  DP_ENABLED,
  SAM_ENABLED,
  SAUL_ENFORCEMENT_ENABLED,
  REQUIRE_ASSOCIATE_ASSIGNMENT,
} from '../constants/nodeFeatures';
import useAcknowledgeAssignment from './useAcknowledgeAssignment';
import { NO_ASSIGNMENT } from '@amzn/staffing-assignments-user-library-web-app';
import useTrainedRolesForAssociate from './useTrainedRolesForAssociate';
import { isExemptFromEnforcement } from '../utils/jobRoleUtils';

export type InitValuesType = {
  metadata: Metadata;
  associateId?: string | string[];
  workstationId?: string;
  interruptible: boolean;
  disableWidget?: boolean;
  onAssignmentPending?: (associateId: string) => void;
  onAssignmentDisplayed?: (associateId: string) => void;
  onAssignmentAccepted?: (staffingAssignment?: UserStaffingAssignment) => void;
};

const clientIdentifierToEnabledFlagMap = new Map<ClientIdentifier, string>([
  ['SAM', SAM_ENABLED],
  ['DIRECTED_PALLETIZE', DP_ENABLED],
]);

/**
 * Check if the provided workstation is authorized for the given assignment
 * @param assignment
 * @param workstationId
 * @param implicit - whether to consider an empty workstationId/assignment as authorized
 */
const isWorkstationAuthorized = (
  assignment: UserStaffingAssignment | undefined,
  workstationId: string | undefined,
  implicit: boolean
) => {
  const { workstation, processSegment } = assignment || {};
  const wsId = workstation?.workstationId;
  const assignedWorkstations = wsId
    ? [wsId]
    : processSegment?.workstations.map((ws) => ws.workstationId);

  const implicitlyAuthed =
    implicit && (!workstationId || !assignedWorkstations?.length);

  return (
    implicitlyAuthed ||
    (workstationId && assignedWorkstations?.includes(workstationId))
  );
};

/**
 * Staffing Assignment User Library (SAUL)
 *
 * @param initValues - Initial values for workstation validation
 * @returns An saul object containing methods for clients
 * to interact with SAUL
 */
export const useStaffingAssignments = (initValues: InitValuesType) => {
  const { INTERRUPTIBLE, WORKSTATION_ID, ROLE } = SAUL_ACTIONS;
  const {
    metadata,
    associateId,
    workstationId,
    interruptible,
    disableWidget,
    onAssignmentPending,
    onAssignmentDisplayed,
    onAssignmentAccepted,
  } = initValues;

  const disabledEnv = metadata.environment === Environment.DISABLED;

  const [state, dispatch] = useReducer(saulReducer, {
    ...initialState,
    associateId,
    workstationId,
    interruptible,
  });
  const [assignment, setAssignment] = useState<
    UserStaffingAssignment | undefined
  >();
  const [workstationAuthorized, setWorkstationAuthorized] = useState(true);
  const [nonEnforcementDisplayed, setNonEnforcementDisplayed] = useState(false);
  const [assignmentPending, setAssignmentPending] = useState(false);
  const client = useGraphQLClient(metadata);

  // Used to ensure the assignment in the setWorkstationId callback stays up to date
  const assignmentRef = useRef(assignment);
  assignmentRef.current = assignment;

  const lastPlaceScannedRef = useRef('');

  const associateIds = useMemo(
    () =>
      associateId
        ? typeof associateId === 'string'
          ? [associateId]
          : associateId
        : [],
    [associateId]
  );

  const { enabledFeatures } = useNodeConfiguration({
    client,
    disabled: disabledEnv,
  });
  const saulEnforcementEnabled = enabledFeatures?.includes(
    SAUL_ENFORCEMENT_ENABLED
  );

  const requireAssociateAssignment = enabledFeatures?.includes(
    REQUIRE_ASSOCIATE_ASSIGNMENT
  );

  const clientFeatureFlag = clientIdentifierToEnabledFlagMap.get(
    metadata.clientIdentifier
  );
  const clientEnabled =
    !clientFeatureFlag || enabledFeatures?.includes(clientFeatureFlag);

  const disabled = disabledEnv || !clientEnabled || disableWidget;

  const { trainedRoles, loading: trainedRolesLoading } =
    useTrainedRolesForAssociate({
      client,
      disabled,
    });

  const { staffingAssignmentsByAssociateId } = useAssociateStaffingAssignments({
    client,
    associateIds,
    disabled,
  });

  const { acknowledgeAssignment } = useAcknowledgeAssignment({ client });

  const onAssignmentAcknowledged = useCallback(
    (staffingAssignment?: UserStaffingAssignment) => {
      if (staffingAssignment) {
        acknowledgeAssignment(staffingAssignment.associateId);
      }
      onAssignmentAccepted?.(staffingAssignment);
    },
    [onAssignmentAccepted]
  );

  const { renderWidget } = useWidget({
    assignment,
    clientIdentifier: metadata.clientIdentifier,
    workstationAuthorized,
    setWorkstationAuthorized,
    nonEnforcementDisplayed,
    requireAssociateAssignment,
    onAssignmentAccepted: onAssignmentAcknowledged,
    onAssignmentDisplayed,
    environment: metadata.environment,
  });

  const assignmentPendingCallback = (id: string | undefined) => {
    setAssignmentPending(true);
    onAssignmentPending?.(id || '');
  };

  useEffect(() => {
    if (
      staffingAssignmentsByAssociateId?.size === 0 &&
      requireAssociateAssignment &&
      !trainedRolesLoading &&
      !isExemptFromEnforcement(trainedRoles)
    ) {
      // Setting to undefined is needed when transitioning from assignment to no assignment states or vice versa
      setAssignment(undefined);
      assignmentPendingCallback(NO_ASSIGNMENT);
    }

    if (staffingAssignmentsByAssociateId?.size) {
      // Until we onboard to kiosks there should only ever be one associateId, so for now we just grab the first one.
      const newAssignment = associateIds.length
        ? staffingAssignmentsByAssociateId?.get(associateIds[0])
        : undefined;
      if (!deepEqual(newAssignment, assignment)) {
        setAssignment(newAssignment);
        assignmentRef.current = newAssignment;
        lastPlaceScannedRef.current = '';
        if (
          !isWorkstationAuthorized(newAssignment, state.workstationId, false) &&
          !isExemptFromEnforcement(trainedRoles, newAssignment) &&
          !trainedRolesLoading
        ) {
          setNonEnforcedMessageEligible();
          assignmentPendingCallback(newAssignment?.associateId);
        }
      }
    }
  }, [
    staffingAssignmentsByAssociateId,
    associateIds,
    assignment,
    assignmentRef,
    lastPlaceScannedRef,
    state,
    requireAssociateAssignment,
    trainedRolesLoading,
    trainedRoles,
  ]);

  useEffect(() => {
    if (assignmentPending && state.interruptible && !disabled) {
      if (!assignment && !requireAssociateAssignment) {
        return;
      }
      renderWidget();
      setAssignmentPending(false);
      setNonEnforcementDisplayed(false);
    }
  }, [
    assignmentPending,
    state.interruptible,
    disabled,
    assignment,
    requireAssociateAssignment,
  ]);

  /**
   * Sets the interruptible flag to pause interruptions.
   *
   * @param interruptible - The new interruptible value.
   */
  const setInterruptible = (interruptible: boolean) => {
    dispatch({ type: INTERRUPTIBLE, interruptible });
  };

  /**
   * If the config store is not set for enforcement enabled, sets the hook that we should once again
   * show the unauthorized but not enforced message instead of just unauthorized.
   * If enforcement is off we only want to display the message when an AA receives
   * or selects a new assignment, or they moved to a new workstation, not on every incorrect scan.
   */
  const setNonEnforcedMessageEligible = () => {
    if (!saulEnforcementEnabled) {
      setNonEnforcementDisplayed(true);
    }
  };

  /**
   * Sets the new workstationId and validates it against the assignment.
   * Returns boolean based on if this workstation is authorized.
   *
   * @param workstationId - The new workstation ID.
   */
  const setWorkstationId = (workstationId?: string) => {
    dispatch({ type: WORKSTATION_ID, workstationId });

    if (!workstationId) {
      lastPlaceScannedRef.current = '';
      setWorkstationAuthorized(true);
      return true;
    }

    const associateIsAtNewWorkstationOrRole =
      workstationId != lastPlaceScannedRef.current;
    lastPlaceScannedRef.current = workstationId;

    const workstationIsAuthorized = isWorkstationAuthorized(
      assignmentRef.current,
      workstationId,
      true
    );
    const unauthorizedAtNewWorkstationOrRole =
      !workstationIsAuthorized && associateIsAtNewWorkstationOrRole;
    const unauthorizedButEnforcementIsDisabled =
      !workstationIsAuthorized &&
      !saulEnforcementEnabled &&
      !associateIsAtNewWorkstationOrRole;

    if (
      workstationIsAuthorized ||
      unauthorizedButEnforcementIsDisabled ||
      isExemptFromEnforcement(trainedRoles, assignmentRef.current)
    ) {
      setWorkstationAuthorized(true);
      return true;
    }

    if (unauthorizedAtNewWorkstationOrRole) {
      setNonEnforcedMessageEligible();
    }
    setWorkstationAuthorized(false);
    assignmentPendingCallback(assignmentRef.current?.associateId);
    return false;
  };

  /**
   * Sets the role the AA is currently working in.
   *
   * @param role
   */
  const setRole = (role?: string) => {
    dispatch({ type: ROLE, role });
  };

  /**
   * Methods available in the SAUL Namespace
   */
  return {
    setInterruptible,
    setWorkstationId,
    setRole,
  };
};

export type StaffingAssignmentsReturnType = ReturnType<
  typeof useStaffingAssignments
>;
